import store from "./store";
import Axios from "axios";

let lang = 'ar';
if (window.localStorage.getItem('lang') !== undefined && window.localStorage.getItem('lang') != null) {
    lang = window.localStorage.getItem('lang');
}
const axios = Axios.create({
    // baseURL: "http://127.0.0.1:8000/api/v1/",
    // baseURL: "http://192.168.1.4:8080/api/v1/",
    baseURL: "https://www.althawri.com/api/v1/",
    headers: {
        "Content-Type": "application/json",
        "x-language": lang,
        "timezone": 'Asia/Riyadh',
        "x-api-key": 'alth4332ifdfsfdsf4645fd342sfa456fdds'
            // "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
    },
});
axios.interceptors.request.use((config) => {
    //console.log(config);
    config.headers.Authorization = store.state.auth.authToken ?
        "Bearer " + store.state.auth.authToken :
        "";
    return config;
});
export default axios; 